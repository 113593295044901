@media (max-width:1400px) {
.container{ width:90%; margin:0 auto; padding:0;}
.hero_sec{ padding:190px 0 110px 0;}
.hero_sec h1{ font-size:70px; line-height:70px; margin-bottom:30px;}
.hero_sec p{ font-size:20px; margin-bottom:30px;}
.hero_btn{ padding:17px 35px; font-size:25px;}
.global_title, .sub_sec h2{ font-size:55px; line-height:65px;} 
.vblock{ padding:0 15px;}

/* .btns{ padding:12px 25px; font-size:20px;} */
.risk_block h4{ font-size:20px;}
.risk_block p{ font-size:18px;}
.faq_sec .card-header .btn-link{ font-size:24px; line-height:normal;}
.sub_sec h3{ font-size:25px;}

.valorant_sec.hero_sec .cap_inn{ max-width: 610px;}
.valorant_sec small { font-size: 18px; line-height: 28px;}
.what_sec{ padding:100px 0 80px 0;}

.proof_sec{ padding:100px 0;}
.what_sec2 {
    padding: 100px 0 100px;
}
}

@media (max-width: 1200px) {
.global_title{ font-size:45px; line-height:55px;} 
.list_info li{ font-size:18px;}
.vblock p, .work_block p, .faq_sec p{ font-size:18px; line-height:25px;}
ul.footer_link li{ font-size:17px;}
.add_block{ font-size:18px;}
.welcome_sec, .risk_sec{ padding-top:100px;}
.video_sec, .work_sec, .bootcamps_sec, .faq_sec{ padding:100px 0 70px 0;}
.sub_sec{ padding:70px 0;}
.sub_sec h2{ font-size:40px; margin-bottom:20px;}
.sub_sec h3{ font-size:20px;}
.copyright{ font-size:17px;}
.footer_logo{ margin-bottom:50px;}
footer{ padding:30px 0 40px 0;}
ul.social_info li a{ width:60px; height:60px; line-height:60px; font-size:26px;}

.what_sec ul.what_srv li .day_info{ padding:30px 0 20px 0;}
.what_sec p { font-size: 18px; line-height: 19px; min-height: 39px;}
.what_sec ul.what_srv li .day_info span{ width: 80px; height: 26px; line-height: 26px; font-size: 15px;}
.proof{ min-height:358px;}
.proof p { font-size: 18px; line-height: 26px;}
.proof .author_info{ margin-bottom:15px;}
.proof_sec .info p { font-size: 18px; line-height: 28px;}
}


/************ Main **************/
@media (max-width: 991px) {
.nav_sec{ padding:15px 0;}
.nav_sec .navbar-light .navbar-nav li{ margin-left:20px;}
.nav_sec .navbar-light .navbar-nav .nav-link{ font-size:15px;}
.nav_sec .navbar-light .navbar-nav .top_btn{ padding:12px 20px;}
.nav_sec .navbar-brand{ width:200px;}
.hero_sec h1{ font-size:50px; line-height:55px;}
.hero_btn{ padding:12px 25px; font-size:20px;}
.welcome_sec figure{ margin:0; text-align:center;}
.welcome_sec .detail{ max-width:100%;}
.global_title{ font-size:35px; margin-bottom:30px;} 
.welcome_sec, .risk_sec{ padding-top:50px;}
.video_sec, .work_sec, .bootcamps_sec, .faq_sec{ padding:50px 0 20px 0;}
.sub_sec{ padding:40px 0;}
.vblock{ padding:0;}
.work_block{ padding:0 10px;}
.btns{ font-size:18px; padding:12px 17px;}
.faq_sec .card-header .btn-link{ font-size:20px;}
.list_info li{ font-size:16px;}
.vblock p, .work_block p, .faq_sec p{ font-size:16px; line-height:22px;}

.sub_sec .input-group .form-control{ height:60px; font-size:17px;}
.sub_sec .input-group i{ top:30px;}
.sub_sec .input-group .btns{ height:60px; font-size:16px;}
.sub_sec h2{ font-size:35px;}
.sub_sec h3{ font-size:17px;}
footer h3{ font-size:18px;}
footer{ padding:20px 0 20px 0;}
.add_block{ font-size:17px;}
.copyright{ margin-top:30px; text-align:center;}

.hero_sec.valorant_sec{ padding: 120px 0 40px 0;}
.valorant_sec.hero_sec h1{ margin-bottom: 20px;}
.valorant_sec.hero_sec .cap_inn { max-width: 470px;}
.valorant_sec.hero_sec p { margin-bottom: 10px;}
.what_sec ul.what_srv{ padding:0;}
.what_sec ul.what_srv li{ padding:0 5px; margin:0 0 20px 0;}
.what_sec { padding: 40px 0 30px 0;}
.what_sec .global_title { margin-bottom: 30px;}
.proof { min-height: 280px;}
.proof_sec { padding: 40px 0;}

.proof_sec .info p { font-size: 16px; line-height: 26px;}

.what_sec2 {
    padding: 40px 0 40px;
}

}

@media (max-width: 767px) {
html { -webkit-text-size-adjust:none;}
.container{ width:90%; margin:0 auto; padding:0; max-width: inherit;}

.navbar-light .navbar-toggler{ background:none; border:none; position:relative; right:0; top:0; height: 33px;}
.navbar-toggler:focus,.navbar-toggler:active { outline: 0;}
.navbar-toggler span {display: block; background-color:#d9ab4d; height:3px; width:25px; margin-top:4px; margin-bottom:4px; -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg);-o-transform: rotate(0deg);  transform: rotate(0deg); position: relative; left: 0;opacity: 1;}
.navbar-toggler span:nth-child(1),.navbar-toggler span:nth-child(3) { -webkit-transition: transform .35s ease-in-out; -moz-transition: transform .35s ease-in-out; -o-transition: transform .35s ease-in-out; transition: transform .35s ease-in-out;}
.navbar-toggler:not(.collapsed) span:nth-child(1) { position: absolute; left: 12px; top: 10px; -webkit-transform: rotate(135deg);  -moz-transform: rotate(135deg); -o-transform: rotate(135deg);transform: rotate(135deg); opacity: 0.9;}
.navbar-toggler:not(.collapsed) span:nth-child(2) {height: 12px; visibility: hidden; background-color: transparent;}
.navbar-toggler:not(.collapsed) span:nth-child(3) {position: absolute;left: 12px;top: 10px;-webkit-transform: rotate(-135deg);-moz-transform: rotate(-135deg); -o-transform: rotate(-135deg);transform: rotate(-135deg);opacity: 0.9;}  

header{ position:relative; background:#000;}

.nav_sec .navbar-light .navbar-nav{ text-align:center;}
.nav_sec .navbar-light .navbar-nav li{ margin:2px 0;}
.nav_sec .navbar-light .navbar-nav .top_btn{ margin:0;}
.hero_sec{ padding:50px 0;}
.hero_sec h1{ font-size:30px; line-height:40px;}
.vblock{ margin-bottom:40px;}
.work_sec .row:before{ display:none;}
.risk_block .detail{ padding-right:0;}
.risk_block p{ font-size:16px; line-height:23px;}
.risk_block h4{ font-size:17px;}
.faq_sec .card-header .btn-link{ padding:20px 60px 20px 25px; font-size:18px;}
.faq_sec .card-header .btn-link::after{ font-size:30px; right:20px;}
.faq_sec .card-body{ padding-left:25px;}
.footer_logo{ margin-bottom:0;}
.copyright{ margin-top:10px;}
ul.social_info li{ clear:none; display:inline-block;}

.hero_sec.valorant_sec { padding: 40px 0 40px 0;}
.what_sec ul.what_srv li { width: 33.333333%;}
.proof{ padding:20px;}
.proof figure{ width:55px;}
.proof .detail { padding: 5px 0 0 10px; font-size: 14px; line-height: 23px;}
.proof .detail strong{ font-size:16px;}
.proof .faceb_link{ font-size:20px;}
.proof p { font-size: 16px; line-height: 26px;}
}

/************ iphone **************/
@media (max-width:600px) {
html { -webkit-text-size-adjust:none;}
.proof p { font-size: 14px; line-height: 23px;}
.what_sec ul.what_srv li { width:50%;}
.what_sec p{ min-height:inherit;}
}


@media (max-width:1800px) {
.global_title{ font-size: 50px;}
/*.ntoday_sec .inner { clip-path: polygon(91.2% 0, 100% 13.2%, 100% 100%, 8.8% 100%, 0 87%, 0 0);}*/

/**/
.nquestion_sec { padding:110px 40px; background-size: 100% auto; bottom: -175px; width: 90%; max-width: 1440px;}
.footer_sec.training { padding-top: 250px;}
.nquestion_sec h2{ margin-bottom: 40px; font-size: 70px;}

.nvod_sec .curve_left{ width: 20%;}
.nvod_sec .curve_right{ width: 20%;}
}

@media (max-width:1579px) {
.container{ width:80%; margin:0 auto; padding:0;}
.global_title{ font-size: 50px; margin-bottom: 30px;}
.nav_sec .navbar-light .navbar-nav .nav-link{ font-size: 20px;}
.top_btn span{ padding: 15px 35px; font-size: 20px;}


ul.user_list li button { padding: 9px 16px; font-size: 18px;}
ul.user_list li button img{ width: 20px;}
ul.day_list{ margin-top: 30px;}
ul.day_list li a{ font-size: 20px;}
.nlearn_block h3{ font-size: 24px;}
.nlearn_block p{ font-size: 18px; line-height: 30px;}
.ntoday_sec p{ font-size: 26px;}
/* .ntoday_sec .inner {clip-path: polygon(89% 0, 100% 13%, 100% 100%, 11% 100%, 0 87%, 0 0);} */
.footer_sec ul.ftr_menu li{ font-size: 22px;}
.ntoday_sec .inner iframe{ height: 30vw;}
.ntoday_sec .today_video::before{ width: 18vw; height: 18vw;}
.ntoday_sec .today_video::after{ width: 18vw; height: 18vw;}
/*.ntoday_sec .inner { clip-path: polygon(92.5% 0, 100% 13%, 100% 100%, 7% 100%, 0 87%, 0 0);}*/
.top_btn span{ font-size: 18px; min-width: 150px;}
.subs_block{ padding: 7px;}
.top_btn::before,.top_btn::after { width: 46px; height: 46px;}
.footer_sec .contact_phone{ font-size: 27px;}
.footer_sec .contact_email{ font-size: 22px;}

/**/
.nlearn_block p { font-size: 16px; line-height: 25px;}
.nlearn_block h3 { font-size: 20px; margin: 0 0 10px; line-height: 30px;}
.nquestion_sec .container{ width: 100%;}
.nquestion_sec { padding: 70px 40px; background-size: 100% auto; bottom: -130px; width: 90%; max-width: 1080px;}
.footer_sec.training { padding-top: 200px;}
.global_title.small { font-size: 50px;}

.nvod_sec .grid{ grid-gap: 30px 20px;}
.nvod_sec .vod_box h3{ font-size: 18px;}

.nvod_sec p{ margin-bottom: 50px;}
.table thead th{ font-size: 18px;}
.table tbody td{ padding: 15px; font-size: 16px; line-height: 26px;}
/*.ncommunity_sec{ padding-bottom: 350px;}*/
.nexperience_sec h3{ font-size: 24px;}
.nquestion_sec h2{ font-size: 60px;}


  .table-p{font-size:18px;}
}

@media (max-width: 1200px) {
.nav_sec .navbar-brand{ width: 200px;}
.nav_sec .navbar-light .navbar-nav li{ margin-right: 50px;}


.global_title { font-size: 50px;}
ul.user_list{ margin-bottom: 30px;}
ul.user_list li button{ font-size: 16px;}
ul.user_list li button img{ width: 16px;}
ul.day_list{ margin-top: 15px;}
ul.day_list li a{ font-size: 16px;}
.nlearn_block h3 { font-size: 20px; line-height: normal;}
.nlearn_block p { font-size: 16px; line-height: 24px;}
.ntoday_sec p{ font-size: 20px;}

.global_title.curve::before { width: 120px;}

.top_btn span{ padding: 10px 20px; }
ul.social_ic li a { padding: 12px; height: 54px; width: 54px;}
.footer_sec h3{ font-size: 23px;}
.footer_sec ul.ftr_menu li{ font-size: 20px;}
.footer_sec .contact_phone{ font-size: 26px; margin-bottom: 10px;}
.footer_sec .contact_phone small{ font-size: 16px; line-height: normal;}
.copyright_sec{ padding: 30px 0;}
.copyright_sec .copyright{ font-size: 18px;}
.copyright_sec p{ font-size: 17px;}

/**/
.nquestion_sec { max-width: 892px;}
.nquestion_sec h2 { margin-bottom: 30px; font-size: 50px;}
.global_title.small { font-size: 40px;}

.nvod_sec .grid{ grid-template-columns: 1fr 1fr 1fr 1fr;}
.nexperience_sec{ padding: 100px 0;}
.global_title{ font-size: 40px;}
  .subs_block .field { padding: 0 16px; font-size: 18px;}
}


/************ Main **************/
@media (max-width: 991px) {
.nav_sec{ padding: 10px 0;}
.nav_sec .navbar-brand { width: 160px;}
.nav_sec .navbar-light .navbar-nav .nav-link { font-size: 16px;}
.nav_sec .navbar-light .navbar-nav li { margin-right: 30px;}


.ntoday_sec .today_video::before { left: -10px; bottom: -10px;}
.ntoday_sec .today_video::after { right: -10px; top: -10px;}

.sub_inn{ padding: 80px 26px 60px;}

.footer_sec{ padding: 80px 0 0;}
.footer_sec.training{ padding-top: 170px;}
.footer_sec .ftr_logo{ margin-bottom: 40px; text-align: center;}
ul.social_ic{ justify-content: center; margin-bottom: 60px;}
.copyright_sec{ margin-top: 70px;}

.nlearn_block figure{ margin: -30px 0 -45px 0;}
.nlearn_block{ margin-bottom: 10px;}
.nvod_sec{ padding: 70px 0;}
.nvod_sec p{ font-size: 18px; line-height: 28px;}
.nvod_sec .grid { grid-template-columns: 1fr 1fr 1fr;}
.ntoday_sec p{ font-size: 18px; margin-bottom: 30px;}
.table thead th{ font-size: 16px;}
.nexperience_sec{ padding: 70px 0;}
.nexperience_sec h3{ font-size: 20px;}
.nquestion_sec h2 { margin-bottom: 20px; font-size: 40px;}
.nexperience_sec .experience_box{ clip-path: polygon(0px 0px, 84.13% 0px, 100% 13.5%, 100.32% 97.81%, 16.73% 99.18%, 0px 84.5%);}
.ntoday_sec .inner iframe{ height: 40vw;}
.table-p{margin-top: 50px;}
  .subs_block { padding: 10px; clip-path: polygon(0 0, 97% 0, 100% 28%, 100% 100%, 3% 100%, 0 78%);}
}

@media (max-width: 767px) {
html { -webkit-text-size-adjust:none;}
.container{ width:90%; margin:0 auto; padding:0; max-width: inherit;}

.nav_sec{ padding: 30px 0;}
.nav_sec .navbar-brand { width: 200px;}
.navbar-light .navbar-toggler{ padding: 0; background:none; border:none; position:relative; right:0; top:0; height: 41px;}
.navbar-toggler:focus,.navbar-toggler:active { outline: 0;}
.navbar-toggler span {display: block; background-color:#d9ab4d; height:3px; width:44px; margin-top:8px; margin-bottom:8px; -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg);-o-transform: rotate(0deg);  transform: rotate(0deg); position: relative; left: 0;opacity: 1;}
.navbar-toggler.collapsed span{ border-radius: 50px;}
.navbar-toggler.collapsed span:nth-child(1){ width: 21px; margin-left: auto;}
.navbar-toggler.collapsed span:nth-child(3){ width: 21px;}
.navbar-toggler span:nth-child(1),.navbar-toggler span:nth-child(3) { -webkit-transition: transform .35s ease-in-out; -moz-transition: transform .35s ease-in-out; -o-transition: transform .35s ease-in-out; transition: transform .35s ease-in-out;}
.navbar-toggler:not(.collapsed) span:nth-child(1) { position: absolute; left: 0; top: 10px; -webkit-transform: rotate(135deg);  -moz-transform: rotate(135deg); -o-transform: rotate(135deg);transform: rotate(135deg); opacity: 0.9;}
.navbar-toggler:not(.collapsed) span:nth-child(2) {height: 12px; visibility: hidden; background-color: transparent;}
.navbar-toggler:not(.collapsed) span:nth-child(3) {position: absolute;left: 0;top: 10px;-webkit-transform: rotate(-135deg);-moz-transform: rotate(-135deg); -o-transform: rotate(-135deg);transform: rotate(-135deg);opacity: 0.9;}
.nav_sec .navbar-collapse{ background: rgba(0,0,0,0.9); padding: 0 20px; position: relative; top: 10px;}
.nav_sec .top_btn{ margin:20px 5px; width: 100%; display: flex; flex-direction: column;}
.nav_sec .top_btn span { clip-path: polygon(0 0, 97% 0, 100% 31%, 100% 100%, 3% 100%, 0 65%);}
.nav_sec .navbar-light .navbar-nav li{ margin: 0;}
.nav_sec .navbar-light .navbar-nav .nav-link{ border-bottom: 1px solid rgba(255,255,255,0.1);}

.global_title{ font-size: 40px;}
.ntoday_sec .global_title{ text-shadow: 0 0 40px #000;}

.footer_sec ul.ftr_menu{ margin-bottom: 60px;}

ul.day_list { margin-top: 70px; justify-content: center;}
ul.user_list li button{ padding: 4px 23px; font-size: 23px;}
ul.day_list li a { font-size: 18px;}

.nquestion_sec{ background: url(question_bg_mob.png) center center no-repeat; background-size: 100% 100%; min-height: 600px; bottom: -300px; text-align: center;}
.footer_sec.training { padding-top: 370px;}
.nvod_sec p{ font-size: 21px; line-height: 35px;}
.nvod_sec .grid { grid-template-columns: 1fr 1fr; padding: 0; grid-gap: 40px 30px;}
.nvod_sec .curve_left{ display: none;}
.nvod_sec .curve_right{ display: none;}
.ntoday_sec p{ font-size: 22px;}
.table thead th span{ display: none;}
.nexperience_sec .experience_box { clip-path: polygon(-1px -2px, 90.47% -5px, 100.38% 13.5%, 100.51% 98.9%, 9.63% 98.36%, -2px 84.77%); max-width: 520px; margin: 0 auto 20px auto;}
.nexperience_sec h3{ max-width: 520px; margin: 0 auto 40px auto; font-size: 26px;}
.nquestion_sec h2{ font-size: 50px;}
.nexperience_sec { padding: 70px 0 30px 0;}
.ntoday_sec .inner iframe{ height: 60vw;}
}

/************ iphone **************/
@media (max-width:600px) {
html { -webkit-text-size-adjust:none;}
.nav_sec { padding: 20px 0;}
.nav_sec .navbar-brand { width: 150px;}
.nav_sec .top_btn span { clip-path: polygon(0 0, 95% 0, 100% 31%, 100% 100%, 5.5% 100%, 0 68%);}


.global_title { font-size: 22px; margin-bottom: 30px;line-height:30px;}
ul.user_list li button { padding: 4px 10px; font-size: 14px;}
ul.day_list { margin-top: 40px;}
ul.day_list li a { font-size: 14px; line-height: normal;}
ul.user_list li button img { width: 14px;}
.nlearn_sec{ padding: 40px 0;}
.nlearn_block{ margin: 0;}
.ntoday_sec p { font-size: 17px; margin-bottom: 30px;}
.sub_inn { padding: 40px 16px 30px;}
.subs_block{ flex-direction: column; padding: 0; clip-path: inherit; background: none; gap: 10px;}
.subs_block .field{ background: #fff; clip-path: polygon(0 0, 97% 0, 100% 28%, 100% 100%, 3% 100%, 0 78%); height: 48px; padding: 0 20px; font-size: 16px;}
.subs_block .top_btn.blue span{ width: 100%; clip-path: polygon(0 0, 91% 0, 100% 34%, 100% 100%, 9% 100%, 0 67%);}
.footer_sec { padding: 50px 0 0;}
ul.social_ic{ margin-bottom: 30px;}
.footer_sec h3 { font-size: 20px; margin-bottom: 20px;}
.footer_sec ul.ftr_menu li { font-size: 16px;}
.footer_sec ul.ftr_menu { margin-bottom: 30px;}
.footer_sec .contact_phone{ font-size: 22px;}
.footer_sec .contact_email{ font-size: 20px;}
.copyright_sec { margin-top: 40px;}
.copyright_sec p { font-size: 15px;}
.copyright_sec .copyright { font-size: 16px;}
.nquestion_sec{ padding: 50px 30px; min-height: 390px;}
.nquestion_sec h2{ font-size: 36px; margin-bottom: 20px;}

.nquestion_sec{ max-width: 340px; bottom: -220px;}
.nvalorant_sec.csgo { padding-bottom: 180px;}
.footer_sec.training { padding-top: 270px;}
.nvod_sec p{ font-size: 16px; line-height: 24px;}
.nvod_sec .grid{ grid-template-columns: 1fr;}
.ntoday_sec p{ font-size: 16px; line-height: 24px;}
.nexperience_sec .experience_box { clip-path: polygon(-1px -2px, 83.86% -1px, 100.38% 13.5%, 100.51% 98.9%, 16.24% 99.45%, -2px 84.77%); max-width: 320px;}
.nexperience_sec h3{ font-size: 22px;}
.top_btn span{ font-size: 16px;}
}